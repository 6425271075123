import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"


const SuccessPage = () => {

  return (
    <Layout>
      <div className="font-happydayPro max-w-7xl mx-auto sm:px-6 lg:px-8 h-screen py-32">
        <div className="max-w-3xl mx-auto px-4 space-y-4">
          <div className="text-4xl">
            Thank you for your generous tip! May the Gods/AI be ever in your favor!
          </div>
          <div>
            <Link className="underline" to="/">Back to Home</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SuccessPage
